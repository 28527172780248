/* You can add global styles to this file, and also import other style files */
@import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "variables";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/font-awesome/css/font-awesome.css";
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import "../../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import "../../node_modules/angular-notifier/styles.css";
@import "material-overrides";

.flag-container > .dropdown-menu.show {
  max-width: 255px !important;
}

body {
  background-color: #f5f5f6;
}

.modal-backdrop {
  z-index: 2040 !important;
}

.modal {
  z-index: 2050;
}

.modal-dialog {
  max-width: 476px;
  margin: 0 auto;
  position: relative;
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.modal-content {
  //	width: 476px;
  border-radius: 6px;
  border: solid 1px #c7ced9;
  background-color: #ffffff;
}

.fab-container {
  position: fixed;
  right: 30px;
  bottom: 30px;
  padding-bottom: 2em;
  z-index: auto;
  .mat-fab {
    z-index: 10;
  }
  .mat-mini-fab {
    opacity: 0;
    z-index: 9;
    -webkit-transform: translateY(-60px);
    -ms-transform: translateY(-60px);
    transform: translateY(-60px);
    -webkit-transition: all 1s ease-out;
    transition: all 1s ease-out;
  }
  .mat-mini-fab-show {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

#sidebar {
  height: 140vh !important;
}

#name-field.no-border .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54) !important;
}
#name-field.no-border.mat-focused .mat-form-field-label {
  color: #2ea2f8 !important;
}

#name-field.mat-form-field-invalid.no-border
  .mat-form-field-label
  .mat-form-field-required-marker {
  color: rgba(0, 0, 0, 0.54) !important;
}
#name-field.mat-form-field-invalid.no-border.mat-focused
  .mat-form-field-label
  .mat-form-field-required-marker {
  color: #ff4081 !important;
}

.mat-menu {
  min-width: 250px;
}

.form-error {
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  //
  //-webkit-transition: all 1s ease-out;
  //transition: all 1s ease-out;
}

.mat-card.name-dropdown {
  border: 1px solid #999999;
  position: absolute;
  width: 93%;
  padding-right: 15px;
}
