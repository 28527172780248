/*
modified items: please keep a list
  - floating labels
  - checkbox colors
*/

//below is a setting that affects all material pop ups on a page that scrolls
//it prevents the side nav and the background color from being scrolled as well
.cdk-global-scrollblock {
  position: static; // this keeps the page from scrolling partially out of site
  overflow: hidden !important; // remove this if you want to show the vertical scroll bar
}

mat-form-field {
  width: 100% !important;
  &.form-input {
    height: 3.125rem;
    width: 100% !important;
    padding: 0.423rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.2;
    color: $form-input-color;
    background-color: $white;
    background-clip: padding-box;
    border: $form-input-border;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    > input {
      width: calc(100% - 1.65rem);
      font-family: $font-family !important;

      &::placeholder {
        color: $form-input-placeholder-color;
        font-weight: $font-weight-normal;
      }
    }

    input.mat-input-element {
      margin-top: 0.2rem !important;
    }

    &.mat-form-field-type-mat-select {
      background-image: linear-gradient(to top, #f2f4f7, $white);
      > select {
        height: calc(2.25rem + 2px);
      }
    }

    &.mat-focused {
      border: 1px solid $form-focus-border-color;
      .mat-form-field-label {
        color: $form-focus-input-color;
      }
    }

    &.mat-form-field-should-float {
      .mat-form-field-infix {
        margin-top: 0;
      }

      .mat-select-value {
        padding-top: 0.3rem;
      }

      .mat-select-arrow:after {
        bottom: 0.5rem;
      }
    }
  }

  .mat-form-field-label {
    color: $form-input-label-color;
  }

  &.mat-form-field-invalid {
    border: 1px solid $danger !important;

    &.mat-focused {
      .mat-form-field-label {
        color: $danger;
      }
    }
  }

  &.mat-form-field-type-mat-input {
    .mat-form-field-infix {
      border-right: 0;
    }
  }
}

::ng-deep .mat-form-field .mat-form-field-outline {
  background-color: white;
}

.mat-form-field-disabled {
  opacity: 0.4;

  &.mat-form-field-type-mat-input {
    background-color: #f5f5f6;
  }
}

.mat-option.mat-selected:not(.mat-option-disabled) {
  color: #0073d0 !important;
  background: #e5f5fc !important;
}

.mat-form-field-underline {
  display: none;
}

.mat-form-field-appearance-outline .mat-form-field-prefix {
  top: 0 !important;
}

.mat-form-field-infix {
  border: 0.5rem solid transparent;
  border-left: none;
  margin-top: -0.5rem;
}

.mat-input-element {
  caret-color: $form-input-color !important;
  font-family: $font-family !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox:not(.mat-checkbox-disabled).mat-accent
  .mat-checkbox-ripple
  .mat-ripple-element {
  background-color: #3185fc !important;
}

.mat-select-arrow:not(::ng-deep .filter-wrapper) {
  border: none !important;
  &:after {
    font-family: "FontAwesome";
    content: "\f078";
    position: relative;
    bottom: 0.3rem;
    font-size: 0.6rem;
    color: $black;
  }
}

.mat-select-panel {
  position: relative;
  top: 2.75rem;
  left: 0.25rem;
}

.mat-form-field-label-wrapper {
  top: -0.45rem;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  //padding: 0;
}

.mat-radio-button {
  .mat-radio-container {
    margin: 0.15rem 0 0;
    width: 1rem;
    height: 1rem;
  }

  .mat-radio-ripple {
    left: calc(50% - 1rem) !important;
    top: calc(50% - 1rem) !important;
    height: 2rem !important;
    width: 2rem !important;
  }

  &.mat-radio-checked {
    .mat-radio-persistent-ripple {
      background-color: lighten($primary, 40%) !important;
    }

    .mat-radio-button.mat-accent:active {
      .mat-radio-persistent-ripple {
        background-color: lighten($primary, 40%) !important;
      }
    }
  }
}

.mat-radio-button.mat-accent {
  .mat-radio-inner-circle {
    background-color: $white;
  }
  &.mat-radio-checked .mat-radio-outer-circle {
    border-color: transparent;
  }
}

.mat-radio-container {
  .mat-radio-outer-circle {
    background-color: $white;
    border: 1px solid #e6e7e8;
    height: 1rem !important;
    width: 1rem !important;
    background-image: linear-gradient(to top, #f2f4f7, $white) !important;
  }
}

.mat-radio-label-content {
  color: $header-font-color;
  font-family: $font-family;
}

// Selected checkbox styles
.mat-radio-checked {
  .mat-radio-container {
    .mat-radio-outer-circle {
      background-color: $primary;
      height: 1rem !important;
      width: 1rem !important;
      background-image: none !important;
    }
    .mat-radio-inner-circle {
      height: 0.875rem !important;
      width: 0.875rem !important;
      top: 0.063rem;
      left: 0.063rem;
    }
  }
  .mat-radio-label-content {
    font-weight: 600;
  }
}

.modal + .cdk-overlay-container {
  z-index: 1051 !important;
}

.mat-form-field-label-wrapper {
  top: -0.45rem;
}

.mat-select,
.mat-form-field-label {
  font-family: $font-family !important;
}

.cdk-overlay-container {
  z-index: 9999 !important;
}

.mat-slide-toggle {
  &.mat-primary.mat-checked {
    .mat-slide-toggle-bar,
    .mat-ripple-element {
      background-color: $primary-alpha;
    }
    .mat-slide-toggle-thumb {
      background-color: $primary;
    }
  }
}

.cdk-overlay-pane {
  max-width: 60vw !important;
}

//BUTTONS:

.mat-fab,
.mat-flat-button {
  &.mat-primary {
    background-color: $primary;
  }
}

.mat-stroked-button {
  background-image: linear-gradient(to top, #f2f4f7, $white);
  border: $form-input-border !important;
  &.mat-primary {
    color: #495057;
  }
}

.mat-stroked-button,
.mat-flat-button {
  font-family: $font-family;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

// OUTLINED INPUT:
.search-wrapper {
  align-items: center;

  mat-form-field {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-align-content: flex-end;
    align-content: flex-end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .mat-form-field-wrapper {
    padding-bottom: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: stretch;
    align-self: stretch;
  }
  ::ng-deep .mat-form-field-flex {
    align-items: baseline !important;
    height: 2.5em !important;
    .mat-form-field-appearance-outline,
    .mat-form-field-outline-thick {
      .mat-form-field-outline-start,
      .mat-form-field-outline-gap,
      .mat-form-field-outline-end {
        border-width: 1px !important;
        border-color: $border-color !important;
        background-color: $white;
      }
    }
  }
  ::ng-deep .mat-form-field-appearance-outline {
    &.mat-form-field-can-float {
      .mat-form-field-label {
        top: 1.6em;
      }
    }
    &.mat-form-field-should-float {
      .mat-form-field-appearance-outline,
      .mat-form-field-outline-thick {
        .mat-form-field-outline-gap {
          border-top-color: transparent !important;
        }
      }
      .mat-form-field-label {
        top: 1.84375em;
      }
    }

    &.mat-focused {
      .mat-form-field-outline-start,
      .mat-form-field-outline-gap,
      .mat-form-field-outline-end {
        border-color: $form-focus-border-color !important;
      }
      .mat-form-field-outline-gap {
        border-top-color: transparent !important;
      }
      .mat-form-field-label {
        color: $form-focus-input-color;
        transform: translateY(-1.55em) scale(0.75) !important;
      }
    }
  }
  ::ng-deep .mat-form-field-appearance-outline {
    .mat-form-field-outline-start,
    .mat-form-field-outline-gap,
    .mat-form-field-outline-end {
    }
    .mat-form-field-label {
      top: 1.6em;
    }
  }
}

//TABLES:

.mat-elevation-z8 {
  &.table-container {
    border-radius: 6px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px $border-color;
    padding: 0.5rem;
    background-color: $white;
  }
}

.mat-table {
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  color: #323a45;

  th.mat-header-cell:last-of-type {
    padding-right: 8px;
  }

  th.mat-header-cell {
    top: -0.5rem !important;
    z-index: 90 !important;
    font-weight: 600;
    color: #323a45;
  }

  @-moz-document url-prefix() {
    th.mat-header-cell {
      border-bottom-width: 2px;
    }
  }

  tr.mat-row {
    height: 36px;
    .mat-cell {
      border-bottom-width: 0;
    }
  }

  tr.mat-row:nth-child(even) {
    background-color: #f1f4f8;
  }
}

// DIALOG: {

.mat-dialog-container {
  border-radius: 10px !important;
  box-shadow: 0 10px 34px 0 rgba(210, 210, 210, 0.5);
  .mat-dialog-title {
    font-family: $font-family;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.45;
    text-align: center;
    color: $header-font-color;
  }

  .mat-dialog-content {
    min-height: unset;
    font-family: $font-family;
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    color: $header-font-color;
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    padding: 1rem 4rem !important;
    /* max-height: 65vh; */
    overflow: hidden;
    border-bottom: solid 2px $border-color;
    &.mat-typography p {
      margin-bottom: 1rem;
    }
    .row {
      justify-content: center;
    }
  }

  .mat-dialog-actions {
    padding: 1.5rem 0.5rem;
  }
}
