$font-family: "Open Sans", serif;

// Colors
$app-background: #e7eaef;
$white: #ffffff;
$black: #000000;
$border-color: #c7ced9;
$header-font-color: #1f1f1f;

$success: #18a966;
$danger: #eb3530;
$warning: #f7981c;
$primary: #0073d0;
$primary-alpha: rgba(0, 115, 208, 0.54);
$primary-light: #e5f5fc;

$brand-blue-light: #0081b3;
$brand-blue-dark: #002856;

$form-input-placeholder-color: #d3d3d3;
$form-input-border: 1px solid #ced4da;
$form-input-color: #495057;
$form-input-label-color: #79797a;
$form-focus-border-color: #2ea2f8;
$form-focus-input-color: #2ea2f8;

$font-weight-normal: 300;
$font-weight-bold: 400;
$font-weight-bolder: 600;

$layout-xl-size: 1200px;
$layout-lg-size: 992px;
$layout-md-size: 768px;
$layout-sm-size: 576px;

$container-width: 1152px;

$margin-buffer: 1rem;
$half-margin-buffer: $margin-buffer * 0.5;

$padding-buffer: 1rem;
$half-padding-buffer: $padding-buffer * 0.5;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

$break-small: 768px;
